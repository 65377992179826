<template>
  <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.72 25.44C4.69333 25.44 3.14667 24.6667 2.08 23.12C1.01333 21.5733 0.48 19.3867 0.48 16.56C0.48 9.57333 3.78667 4.34667 10.4 0.879999L12.64 4.96C10.4 6.24 8.72 7.65333 7.6 9.2C6.53333 10.7467 6 12.6933 6 15.04C6.16 14.9867 6.4 14.96 6.72 14.96C8.16 14.96 9.38667 15.4133 10.4 16.32C11.4667 17.1733 12 18.3733 12 19.92C12 21.6267 11.4933 22.9867 10.48 24C9.52 24.96 8.26667 25.44 6.72 25.44ZM23.28 25.44C21.2533 25.44 19.7067 24.6667 18.64 23.12C17.5733 21.5733 17.04 19.3867 17.04 16.56C17.04 9.57333 20.3467 4.34667 26.96 0.879999L29.2 4.96C26.96 6.24 25.28 7.65333 24.16 9.2C23.0933 10.7467 22.56 12.6933 22.56 15.04C22.72 14.9867 22.96 14.96 23.28 14.96C24.72 14.96 25.9467 15.4133 26.96 16.32C28.0267 17.1733 28.56 18.3733 28.56 19.92C28.56 21.6267 28.0533 22.9867 27.04 24C26.08 24.96 24.8267 25.44 23.28 25.44Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'Aspas',

  props: {
    color: {
      type: String,
      default: '#8D53BD',
    },
  },
}
</script>
