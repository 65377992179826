<template>
  <div class="card-border">
    <b-row class="content">
      <b-col cols="2" class="v-align">
        <b-icon font-scale="1.5" :icon="icon" :color="color" />
      </b-col>
      <b-col cols="10" class="v-align">
        <span>{{ text }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BIcon } from 'bootstrap-vue'

export default {
  name: 'CardBorderIcon',

  components: { BRow, BCol, BIcon },

  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#333',
    },
  },
}
</script>

<style scoped>
.card-border {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  border-radius: 8px;
  padding: 18px 30px;
}

@media screen and (min-width: 601px) {
  .content {
    height: 100px;
  }
}
</style>
