<style scoped>
h4 {
  font-weight: 600;
}
.bg-cover {
  background-size: cover;
}

.img-profile {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.slider-item {
  background-size: cover;
  border-radius: 12px;
  text-align: left;
  font-size: 100%;
  display: block;
  margin-right: 2%;
}

.slider-fade-overlay {
  padding: 25px;
  border-radius: 12px;
  padding-bottom: 10px;
}

.slider-container {
  white-space: normal;
}

.slider-meters {
  font-size: 1.3rem;
  line-height: 2rem;
  float: right;
}

.slider-title {
  font-size: 200%;
}

.slider-p p {
  line-height: 0.8rem;
}

.fade-overlay {
  /* -webkit-mask-image: linear-gradient(to left, transparent, black 50%);
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
}

.slider-fade-overlay {
  background: #fff;
  margin-bottom: 20px;
  height: 400px;
}

._card {
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.3);
}

._avatar {
  width: 100%;
}

@media screen and (max-width: 600px) {
  ._avatar {
    width: 70px;
  }
}
</style>

<template>
  <div>
    <!--div style="float: right; margin-top: -100px">
      <icon-circle icon="arrow-left" :color="color" :click="goNext"></icon-circle>
      <icon-circle icon="arrow-right" :color="color" :click="goPrev" class="ml-2"></icon-circle>
    </div-->

    <div class="fade-overlay mt-5">
      <swiper ref="slider" :options="options">
        <swiper-slide v-for="(item, index) in itemList" :key="index">
          <div class="slider-fade-overlay _card flex-container">
            <aspas :color="color" class="mb-3" />

            <p class="text-dark">
              {{ item.texto }}
            </p>

            <b-row>
              <b-col sm="3" style="margin-top: 10px">
                <img class="rounded-circle _avatar" :src="require(`../assets/images/${item.imagem}`)">
              </b-col>
              <b-col sm="9" style="padding: 8px">
                <h4>{{ item.nome }}</h4>
                <span style="color: #999">{{ item.cargo }}</span>
              </b-col>
            </b-row>
          </div>
        </swiper-slide>

        <div slot="button-prev" class="swiper-button-prev" />
        <div slot="button-next" class="swiper-button-next" />

        <div slot="loading">Carregando...</div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import Aspas from './icons/Aspas.vue'

export default {
  name: 'SliderQuotesAnunciar',

  components: {
    BRow,
    BCol,
    Aspas,
    Swiper,
    SwiperSlide,
    // IconCircle,
  },

  props: {
    items: {
      type: Number,
      default: 2,
    },
    color: {
      type: String,
      default: '#333',
    },
  },

  data() {
    return {
      itemList: [],
      width: 102 / this.items - 2,
      options: {
        slidesPerView: 5,
        spaceBetween: 50,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          800: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },

  mounted() {
    this.obterItems()
  },

  methods: {
    async obterItems() {
      this.itemList = await this.$store.dispatch('site/obterTestemunhos')
    },

    goto(url) {
      window.location.href = url
    },

    goNext() {
      this.$refs.slider.$emit('slideNext')
    },

    goPrev() {
      this.$refs.slider.$emit('slidePre')
    },
  },
}
</script>
