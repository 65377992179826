<template>
  <b-row>
    <b-col sm="3"><StepItem step="01" title="Faça seu cadastro" text="Preencha as informações do formulário de cadastro." /></b-col>
    <b-col sm="3"><StepItem step="02" title="Cadastre o seu imóvel" text="Preencha todas as informações e detalhes sobre o seu imóvel na planta. " /></b-col>
    <b-col sm="3"><StepItem step="03" title="Aguarde a aprovação da equipe Divvy" text="Nossa equipe irá avaliar os cadastros e enviar os documentos para sua assinatura. " /></b-col>
    <b-col sm="3"><StepItem step="04" title="Pronto!" text="Seu imóvel na planta ficará disponível em nossa plataforma para venda das cotas, agora é só acompanhar todo o desempenho dentro do seu perfil.  " /></b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StepItem from './StepItem.vue'

export default {
  name: 'Home',

  components: {
    StepItem,
    BRow,
    BCol,
  },
}
</script>
