<template>
  <div>
    <div class="site-container">
      <banner
        title="Anuncie o seu imóvel de forma fácil, rápida e acessível!"
        :image="require('../assets/images/banner-construtora.png')"
        btn-text="Seja um parceiro Divvy!"
        @btnClick="() => $router.push({ name: 'register-profile' })" />
    </div>

    <div class="site-container">
      <step-banner class="my-5" />
    </div>

    <background size="contain" repeat="repeat-x" :image="require('../assets/images/bg-green-banner.png')">
      <background size="100%" position="center top" :image="require('../assets/images/divvy-vector-line.png')">
        <div class="site-container">
          <h1 class="text-center text-white bold py-5">
            Ainda não entendeu?<br>
            Explicamos para você!
          </h1>
          <div class="site-content">
            <div style="height: 40vw; max-height: 500px">
              <youtube color="#44B481" src="https://www.youtube.com/embed/EZvn9l4A2rI" />
            </div>

            <!-- <b-row class="mt-4">
              <b-col cols="2">
                <link-seta />
              </b-col>
              <b-col cols="10">
                parceiros-contrutoras style="width: 100%" /
              </b-col>
            </b-row> -->
          </div>
        </div>
      </background>
    </background>

    <div class="site-container mt-5">
      <h1 class="text-center bold py-3">Como funciona?</h1>
      <action-flux-construtora style="width: 100%" />
    </div>

    <Background position="center center" :image="require('@site/assets/images/bg-vector-green.png')">
      <div class="site-container" style="padding-top: 180px">
        <div class="site-content">
          <h1 style="font-weight: 700">Benefícios de ser um Parceiro Divvy</h1>
          <b-row class="mt-5" size="sm">
            <b-col md="6">
              <card-border-icon icon="eye" color="#5BD69E" text="Maior visibilidade para você e seu imóvel na planta, em conjunto com um melhor posicionamento e reforço de marca. " />
              <card-border-icon icon="check" color="#5BD69E" text="Canal de venda inovador para ofertar os imóveis na planta da carteira da construtora. " />
              <card-border-icon icon="graph-up" color="#5BD69E" text="Aumento do marketshare dentro do mercado de investimentos." />
              <card-border-icon icon="person-plus-fill" color="#5BD69E" text="Atingir um novo público expressivo de compradores de cotas imobiliárias." />
            </b-col>
            <b-col md="6">
              <card-border-icon icon="bar-chart-fill" color="#5BD69E" text="Recebimento de capital com maior agilidade de acordo com a venda das cotas do imóvel." />
              <card-border-icon icon="phone" color="#5BD69E" text="Acesso a plataforma online para acompanhamento das vendas das cotas, comissões e desempenho dos imóveis. " />
              <card-border-icon icon="wifi" color="#5BD69E" text="Todo o processo feito de maneira 100% online, simples e fácil para a construtora e seus clientes." />
            </b-col>
          </b-row>
        </div>
      </div>
    </Background>

    <Background :image="require('@site/assets/images/bg-logo-line-green.png')">
      <div class="site-container" style="padding-top: 100px">
        <div
          class="component-container"
          style="border-radius: 12px"
          :style="{ backgroundSize: '100%', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../assets/images/bg-vector-rounded-green.png')})` }">
          <h1 style="color: #fff; font-weight: 700">Por que a Divvy?</h1>
          <slider-quotes-anunciar color="#44B481" />
        </div>

        <newsletter-card class="mt-4" />
      </div>
    </Background>

    <Footer />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import mixin from '@/mixins'
import StepBanner from '../components/step-banner-3/StepBanner.vue'
import CardBorderIcon from '../components/CardBorderIcon.vue'
import SliderQuotesAnunciar from '../components/SliderQuotesAnunciar.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import Footer from '../components/parts/footer/Footer.vue'
// import LinkSeta from '../components/LinkSeta.vue'
import Youtube from '../components/Youtube.vue'
import Background from '../components/Background.vue'
import ActionFluxConstrutora from '../components/ActionFluxConstrutora.vue'

import Banner from '../components/Banner.vue'

export default {

  components: {
    // ParceirosContrutoras,
    SliderQuotesAnunciar,
    CardBorderIcon,
    StepBanner,
    BRow,
    BCol,
    NewsletterCard,
    Footer,
    // LinkSeta,
    Youtube,
    Background,
    ActionFluxConstrutora,
    Banner,
  },
  mixins: [mixin],
}
</script>

<style scoped>
.component-container {
  padding: 40px;
}

@media screen and (max-width: 600px) {
  .component-container {
    padding: 5vw;
  }
}
</style>
