<style scoped>
.col,
.col p,
.col h1 {
  padding: 0px;
  color: #ffffff;
  text-shadow: 1px 1px 2px black;
}

.banner {
  padding: 120px;
}

@media screen and (max-width: 1024px) {
  .banner {
    padding: 10vw;
  }
}

@media screen and (min-width: 601px) {
  .col h1 {
    font-size: 300%;
  }
  .col p {
    font-size: 200%;
  }
  .act {
    margin-top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .col h1 {
    font-size: 10vw;
  }
  .col p {
    font-size: 100%;
  }
  .act {
    margin-top: 10px;
  }
}
</style>

<template>
  <div class="banner" :style="[imgStyle, { backgroundImage: `url(${image})` }]">
    <div class="site-container">
      <b-row>
        <b-col sm="8" class="col">
          <h1>{{ title }}</h1>
          <p>{{ text }}</p>
        </b-col>
        <b-col sm="4" />
      </b-row>

      <b-row class="act">
        <b-col class="col">
          <b-button v-if="winSM" :variant="variant" size="md" @click="$emit('btnClick')">{{ btnText }}</b-button>
          <b-button v-else :variant="variant" size="lg" @click="$emit('btnClick')">{{ btnText }}</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'

import mixin from '@/mixins'

export default {

  components: { BRow, BCol, BButton },
  mixins: [mixin],

  props: {
    variant: {
      type: String,
      default: 'green',
    },
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'Click here',
    },
  },

  data() {
    return {
      imgStyle: {
        borderRadius: '10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    }
  },
}
</script>
