<template>
  <div :style="style">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Background',

  props: {
    image: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: '120%',
    },

    repeat: {
      type: String,
      default: 'no-repeat',
    },

    position: {
      type: String,
      default: 'center top',
    },
  },

  data() {
    return {
      style: {
        backgroundSize: this.$props.size,
        backgroundRepeat: this.$props.repeat,
        backgroundPosition: this.$props.position,
        backgroundImage: `url(${this.$props.image})`,
      },
    }
  },
}
</script>
