<template>
  <div style="display: flex" class="mb-2">
    <span :style="style">{{ step }}</span>
    <div>
      <div class="step-title">
        {{ title }}
      </div>
      <div class="step-text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepItem',

  props: {
    step: {
      type: String,
      default: '01',
    },

    title: {
      type: String,
      default: '',
    },

    text: {
      type: String,
      default: '',
    },

    color1: {
      type: String,
      default: '#5BD69E',
    },

    color2: {
      type: String,
      default: '#29BB78',
    },
  },

  data() {
    return {
      style: {
        'font-size': '4rem',
        padding: '0 10px',
        'font-weight': '600',
        'line-height': '0.8',
        'background-color': this.$props.color1,
        'background-image': `linear-gradient(90deg, ${this.$props.color1}, ${this.$props.color2})`,
        'background-size': '100%',
        '-webkit-background-clip': 'text',
        '-moz-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        '-moz-text-fill-color': 'transparent',
      },
    }
  },
}
</script>

<style>
.step-title {
  font-weight: 700;
  padding: 0 10px 0 0;
}

.step-text {
  font-size: 0.9rem;
}

.step-number {
  /* font-size: 4rem;
  padding: 0 10px;
  font-weight: 600;
  line-height: 0.8;
  background-color: #c490ef;
  background-image: linear-gradient(90deg, #c490ef, #8e52c0);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; */
}
</style>
